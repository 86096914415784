import React, { useContext } from 'react'
import { Wrapper, LearningGoal, Title, AddButton } from './index.styles'
import { useContainer } from 'unstated-next'
import { CurriculumState } from '../index.state'
import { TextInput } from 'grommet'
import Challenges from '../Challenges'
import TypeFilters from './TypeFilters'
import { FormAdd } from 'grommet-icons'
import ModalContext from '../../Context/ModalContext'
import CreateChallengeModal from '../../CreateChallengeModal'
import { Link } from 'gatsby'

const ChallengesList = (props) => {
  const { learningGoals, loading, searchValue, setSearchValue } = useContainer(CurriculumState)
  const modal = useContext(ModalContext)

  const onCreateChallengeAction = (learningGoalId) => {
    modal.open(<CreateChallengeModal learningGoalId={learningGoalId} />)
  }

  if (loading) return null
  return <Wrapper>
    <TextInput onChange={e => setSearchValue(e.target.value)} value={searchValue} placeholder='Search for a Learning Goal' />
    <TypeFilters />
    <div>
    {
      learningGoals.map((goal) => <LearningGoal key={goal._id}>
        <Title><Link to={`/learning-goal/${goal._id}`}>{goal.title} - {goal.nbChallenges}</Link></Title>
        <Challenges droppableId={goal._id} key={`challenges-${goal._id}`} challenges={goal.challenges} />
        <AddButton onClick={() => onCreateChallengeAction(goal._id)}>
          <FormAdd color='#fff' />
        </AddButton>
      </LearningGoal>)
    }
    </div>
  </Wrapper>
}

ChallengesList.propTypes = {

}

ChallengesList.defaultProps = {

}

export default ChallengesList
