import React from 'react'
import { Wrapper, Content } from './index.styles'
import ChallengesList from './ChallengesList'
import { CurriculumState } from './index.state'
import CurriculumView from './CurriculumView'
import { DragDropContext } from 'react-beautiful-dnd';
import { useContainer } from 'unstated-next'

const CurriculumEditor = (props) => {
  return <Wrapper>
    <Content>
      <CurriculumView />
      <ChallengesList />
    </Content>
  </Wrapper>
}

CurriculumEditor.propTypes = {

}

CurriculumEditor.defaultProps = {

}

export const DragDropContainer = ({ children }) => {
  const { moveChallengeToCurriculum, moveCurriculumChallenge, removeCurriculumChallenge } = useContainer(CurriculumState)

  const onDragEnd = (e) => {
    const { destination, source } = e
    if (!source || !destination) return
    if (source.droppableId !== 'curriculum' && destination.droppableId === 'curriculum') {
      moveChallengeToCurriculum(source.droppableId, source.index, destination.index)
    }
    if (source.droppableId === 'curriculum' && destination.droppableId === 'curriculum') {
      moveCurriculumChallenge(source.index, destination.index)
    }
    if (source.droppableId === 'curriculum' && destination.droppableId !== 'curriculum') {
      removeCurriculumChallenge(source.index, destination.index)
    }
  }

  return <DragDropContext onDragEnd={onDragEnd}>
    { children }
  </DragDropContext>
}

export default (props) => (
  <CurriculumState.Provider>
    <DragDropContainer>
      <CurriculumEditor {...props} />
    </DragDropContainer>
  </CurriculumState.Provider>
)
