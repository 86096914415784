import React from 'react'
import Layout from '../components/layout'
import CurriculumEditor from '../components/CurriculumEditor'

const CurriculumEditorPage = () => (
  <Layout>
    <CurriculumEditor />
  </Layout>
)

export default CurriculumEditorPage
