import styled from 'styled-components'
import { colors } from '../../../theme'

export const Wrapper = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
  > :first-child, > :nth-child(2) {
    margin-bottom: 10px;
  }
  > :last-child {
    flex: 1;
    height: 100%;
    overflow: scroll;
  }
`

export const LearningGoal = styled.div`
  font-family: 'Montserrat';
  border: 2px dashed ${colors.indigo}66;
  padding: 10px 12px 0 12px;
  margin-bottom: 15px;
  border-radius: 9px;
`

export const Title = styled.div`
  font-weight: bold;
  margin-bottom: 15px;
  a {
    color: inherit;
  }
`

export const AddButton = styled.div`
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin: 0 10px;
  cursor: pointer;
  background-color: ${colors.indigo};
  border-radius: 8px;
  margin-bottom: 20px;
`
