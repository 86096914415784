import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  padding-top: 30px;
  height: calc(100vh - 50px);
  overflow: hidden;
`

export const Content = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
`
