import React from "react"
import Challenge from "../Challenge"
import styled from "styled-components"
import { Droppable } from "react-beautiful-dnd"
import { colors } from "../../../theme"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: 60px;
  height: 100%;
  background-color: ${props =>
    props.draggingOver ? colors.lightGray : colors.lighterGray};
  border-radius: 8px;
  padding: 10px;
  > * {
    margin: 5px 0;
  }
`

const Challenges = ({ droppableId, challenges }) => {
  return (
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <Wrapper ref={provided.innerRef} draggingOver={snapshot.isDraggingOver}>
          {challenges.map((challenge, i) =>
            <Challenge
              key={challenge._id}
              {...challenge}
              index={i}
            />
          )}
          {provided.placeholder}
        </Wrapper>
      )}
    </Droppable>
  )
}

Challenges.propTypes = {}

Challenges.defaultProps = {}

export default Challenges
