import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 600px;
  > :first-child {
    margin-bottom: 25px;
  }
  > :nth-child(2) {
    max-height: calc(100vh - 130px);
    overflow-y: scroll;
  }
`
