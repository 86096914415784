import React, { useContext } from "react"
import styled from "styled-components"
import { colorForChallengeType } from "../../LearningGoal/index.styles"
import { Draggable } from "react-beautiful-dnd"
import { View } from "grommet-icons"
import ModalContext from "../../Context/ModalContext"
import ChallengePreview from "../../ChallengePreview"
import ChallengeLangControl from "../../ChallengeLangControl"
import DuplicateChallengeButton from "../../DuplicateChallengeButton"
import { useContainer } from "unstated-next"
import { CurriculumState } from "../index.state"
import { Button, Eye } from 'nzk-react-components'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  padding: 0 15px;
  height: 40px;
  width: 100%;
  align-items: center;
  overflow-x: hidden;
  background-color: ${props => colorForChallengeType(props.type)};
  border-radius: 6px;
  > :first-child {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > :nth-child(2) {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: auto;
  }
`

const Actions = styled.div`
  width: auto !important;
  display: flex;
  > * {
    margin-right: 8px;
  }
  > :last-child {
    margin-right: 0;
  }
`

const Challenge = ({ _id, learningGoal, title, explanation, type, index, us, uk }) => {
  const modal = useContext(ModalContext)
  const { addChallenge } = useContainer(CurriculumState)

  function extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  return (
    <Draggable key={_id} draggableId={_id} index={index}>
      {(provided, snapshot) => (
        <Wrapper
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          type={type}
          style={{
            ...provided.draggableProps.style
          }}
        >
          <div>#{index + 1} - { learningGoal && learningGoal.category.title } - { title }</div>
          <Actions>
            <DuplicateChallengeButton _id={_id} onDuplicatedChallenge={addChallenge} />
            <ChallengeLangControl _id={_id} us={us} uk={uk} />
            <Button round theme='primary' size='x-small' onClick={() => modal.open(<ChallengePreview _id={_id} />)}><Eye /></Button>
          </Actions>
        </Wrapper>
      )}
    </Draggable>
  )
}

Challenge.propTypes = {}

Challenge.defaultProps = {}

export default Challenge
