import React from 'react'
import { Wrapper } from './index.styles'
import { useContainer } from 'unstated-next'
import { CurriculumState } from '../index.state'
import { Button } from 'grommet'
import { colors } from '../../../theme'
import Challenges from '../Challenges'
import Loader from '../../UI/Loader'

const CurriculumView = (props) => {
  const { curriculum, loading, createCurriculum } = useContainer(CurriculumState)
  
  if (loading) return <Loader />

  if (!curriculum && !loading) return <Wrapper>
    <Button primary color={colors.indigo} onClick={createCurriculum}>Create</Button>
  </Wrapper>

  return <Wrapper>
    <div>{ curriculum.title }</div>
    <div>
      <Challenges droppableId='curriculum' challenges={curriculum.challenges} />
    </div>
  </Wrapper>
}

CurriculumView.propTypes = {

}

CurriculumView.defaultProps = {

}

export default CurriculumView
